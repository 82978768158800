import { Injectable } from '@angular/core';

interface StorageItem {
  value: any;
  expiresAt: number;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  setItem(key: string, value: any, expiresInSeconds: number) {
    const expiresAt = new Date().getTime() + (expiresInSeconds * 1000);
    const item: StorageItem = {
      value,
      expiresAt
    };
    
    localStorage.setItem(key, JSON.stringify(item));
  }

  getItem(key: string): any {
    const itemStr = localStorage.getItem(key);
    
    // Return null if item doesn't exist
    if (!itemStr) {
      return null;
    }

    const item: StorageItem = JSON.parse(itemStr);
    const now = new Date().getTime();

    // Compare the expiry time of the item with the current time
    if (now > item.expiresAt) {
      // If the item is expired, remove it from storage and return null
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  // Optional: Get time remaining before expiration (in seconds)
  getTimeRemaining(key: string): number {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return 0;
    }

    const item: StorageItem = JSON.parse(itemStr);
    const now = new Date().getTime();
    const timeRemaining = Math.round((item.expiresAt - now) / 1000);
    
    return timeRemaining > 0 ? timeRemaining : 0;
  }
} 