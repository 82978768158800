<div class="first-mile-container">
    <img src="assets/icons/7h-nexgram-logo-2.svg" alt="Seven Hills Nexgram Logo" class="logo" />
    <h2 class="title">First Mile Enrollment</h2>
    <form [formGroup]="firstMileForm" (ngSubmit)="onSubmit()" class="enrollment-form">
        <div *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
        <div class="form-grid">
            <div class="form-group">
                <label for="business_name">Business Name:</label>
                <input id="business_name" formControlName="business_name" required class="form-control" 
                    placeholder="Official business name" />
            </div>

            <div class="form-group">
                <label for="business_type">Business Type:</label>
                <input id="business_type" formControlName="business_type" required class="form-control" 
                    placeholder="Business type such as Proprietorship, Partnership, Private Limited, Public Limited etc." />
            </div>

            <div class="form-group">
                <label for="business_nature">Business Nature:</label>
                <input id="business_nature" formControlName="business_nature" required class="form-control" 
                    placeholder="Governed Under" />
            </div>

            <div class="form-group">
                <label for="owner_pan">Owner PAN:</label>
                <input id="owner_pan" formControlName="owner_pan" required class="form-control" 
                    placeholder="Enter owner's PAN number" />
            </div>

            <div class="form-group">
                <label for="company_pan">Company PAN:</label>
                <input id="company_pan" formControlName="company_pan" required class="form-control" 
                    placeholder="Enter company's PAN number" />
            </div>

            <div class="form-group">
                <label for="cin">CIN:</label>
                <input id="cin" formControlName="cin" class="form-control" 
                    placeholder="Enter Company Identification Number if any" />
            </div>

            <div class="form-group">
                <label for="business_address">Business Address:</label>
                <input id="business_address" formControlName="business_address" required class="form-control" 
                    placeholder="Enter complete business address" />
            </div>

            <div class="form-group">
                <label for="authorized_signatory">Authorized Signatory:</label>
                <input id="authorized_signatory" formControlName="authorized_signatory" required class="form-control" 
                    placeholder="Enter authorized signatory name" />
            </div>

            <div class="form-group">
                <label for="pincode">Pincode:</label>
                <input id="pincode" formControlName="pincode" required maxlength="6" class="form-control" 
                    placeholder="Enter 6-digit pincode" />
            </div>

            <div class="form-group">
                <label for="district">District:</label>
                <select id="district" formControlName="district" required class="form-control">
                    <option value="" disabled selected>Select your district</option>
                    <option *ngFor="let district of districts" [value]="district">{{ district }}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="sector">Sector:</label>
                <select id="sector" formControlName="sector" required class="form-control">
                    <option value="" disabled selected>Select your sector</option>
                    <option *ngFor="let sector of sectors" [value]="sector.name">{{ sector.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="original_enroll_ref">Original Enrollment Reference:</label>
                <input id="original_enroll_ref" formControlName="original_enroll_ref" required class="form-control" 
                    placeholder="Enter original enrollment reference" />
            </div>

            <div class="form-group">
                <label for="owner_email">Owner Email:</label>
                <input id="owner_email" formControlName="owner_email" required type="email" class="form-control" 
                    placeholder="Enter owner's email address" />
            </div>

            <div class="form-group">
                <label for="owner_mobile">Owner Mobile:</label>
                <input id="owner_mobile" formControlName="owner_mobile" required maxlength="10" class="form-control" 
                    placeholder="Enter 10-digit mobile number" />
            </div>

            <div class="form-group">
                <label for="documentPAN">PAN Document:</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                    [fileExtensions]="['doc', 'docm', 'docx', 'pdf', 'jpg', 'jpeg', 'png']"
                    [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                    (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                    (fileAdd)="getSrcFile($event, 'pan')" (uploadFile)="uploadFile($event)"
                    (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
                <ng-container *ngIf="pan_collection.length > 0">
                    <app-file-preview-slider class="cursor-pointer" [imgCollection]="pan_collection"
                        [isDeleteable]="true" (deleteFile)="removeFile(pan_collection,'pan')"></app-file-preview-slider>
                </ng-container>
            </div>

            <div class="form-group">
                <label for="documentAddress">Business Address Proof:</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                [fileExtensions]="['pdf', 'jpg', 'jpeg', 'png']"
                [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                (fileAdd)="getSrcFile($event, 'compAddress')" (uploadFile)="uploadFile($event)"
                (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
            <ng-container *ngIf="compAddress_collection.length > 0">
                <app-file-preview-slider class="cursor-pointer" [imgCollection]="compAddress_collection"
                    [isDeleteable]="true" (deleteFile)="removeFile(compAddress_collection,'compAddress')"></app-file-preview-slider>
            </ng-container>
            </div>

            <div class="form-group">
                <label for="documentCOI">COI Document:</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                [fileExtensions]="['pdf', 'jpg', 'jpeg', 'png']"
                [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                (fileAdd)="getSrcFile($event, 'compCOI')" (uploadFile)="uploadFile($event)"
                (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
            <ng-container *ngIf="compCOI_collection.length > 0">
                <app-file-preview-slider class="cursor-pointer" [imgCollection]="compCOI_collection"
                    [isDeleteable]="true" (deleteFile)="removeFile(compCOI_collection,'compCOI')"></app-file-preview-slider>
            </ng-container>
            </div>

            <div class="form-group">
                <label for="documentPicture">Business location picture with geotag</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                [fileExtensions]="['pdf', 'jpg', 'jpeg', 'png']"
                [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                (fileAdd)="getSrcFile($event, 'compPicture')" (uploadFile)="uploadFile($event)"
                (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
            <ng-container *ngIf="compPicture_collection.length > 0">
                <app-file-preview-slider class="cursor-pointer" [imgCollection]="compPicture_collection"
                    [isDeleteable]="true" (deleteFile)="removeFile(compPicture_collection,'compPicture')"></app-file-preview-slider>
            </ng-container>
            </div>

            <div class="form-group">
                <label for="documentAADHAR">AADHAR Card:</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                [fileExtensions]="['pdf', 'jpg', 'jpeg', 'png']"
                [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                (fileAdd)="getSrcFile($event, 'aadhar')" (uploadFile)="uploadFile($event)"
                (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
            <ng-container *ngIf="aadhar_collection.length > 0">
                <app-file-preview-slider class="cursor-pointer" [imgCollection]="aadhar_collection"
                    [isDeleteable]="true" (deleteFile)="removeFile(aadhar_collection,'aadhar')"></app-file-preview-slider>
            </ng-container>
            </div>

            <div class="form-group">
                <label for="documentAffidavit">Affidavit Document:</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                [fileExtensions]="['pdf', 'jpg', 'jpeg', 'png']"
                [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                (fileAdd)="getSrcFile($event, 'affidavit')" (uploadFile)="uploadFile($event)"
                (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
            <ng-container *ngIf="affidavit_collection.length > 0">
                <app-file-preview-slider class="cursor-pointer" [imgCollection]="affidavit_collection"
                    [isDeleteable]="true" (deleteFile)="removeFile(affidavit_collection,'affidavit')"></app-file-preview-slider>
            </ng-container>
            </div>

            <div class="form-group">
                <label for="documentUndertaking">Undertaking Document:</label>
                <app-fileuploder #uploader [fileMaxSize]="2" [accept]="'.jpg, .png,.pdf,.jpeg'"
                [fileExtensions]="['pdf', 'jpg', 'jpeg', 'png']"
                [postApiUrl]="documentPostUrl" [deleteApiUrl]="'/delete'" (validationError)="fileValidation($event)"
                (uploadSuccess)="fileUploaded($event)" (removeSuccess)="fileRemoved($event)"
                (fileAdd)="getSrcFile($event, 'undertaking')" (uploadFile)="uploadFile($event)"
                (removeFile)="removeFile($event)" [cropper]="false"></app-fileuploder>
            <ng-container *ngIf="undertaking_collection.length > 0">
                <app-file-preview-slider class="cursor-pointer" [imgCollection]="undertaking_collection"
                    [isDeleteable]="true" (deleteFile)="removeFile(undertaking_collection,'undertaking')"></app-file-preview-slider>
            </ng-container>
            </div>

            <div class="form-group">
                <label for="refId">Reference ID:</label>
                <input id="refId" formControlName="refId" required class="form-control" />
            </div>
        </div>

        <div *ngIf="loading" class="spinner">
            Loading..
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
    </form>

    <div *ngIf="showModal" class="modal">
        <div class="modal-content">
            <span class="close" (click)="closeModal()">&times;</span>
            <h2>Your submission is successful. We shall be in touch with you shortly. In the meanwhile, you can download
                the draft version of NDA.</h2>
            <button (click)="downloadNDA()">Download draft NDA Document</button>
        </div>
    </div>
</div>
<!-- Loading overlay -->
<div *ngIf="loading" class="loading-overlay">
    <div class="spinner">
        <!-- You can use an SVG spinner or a CSS spinner -->
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" stroke="gray" stroke-width="5" fill="none" />
            <circle cx="25" cy="25" r="20" stroke="blue" stroke-width="5" fill="none" stroke-dasharray="31.4 31.4"
                stroke-dashoffset="0">
                <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s"
                    repeatCount="indefinite" />
            </circle>
        </svg>
        <p>Loading... Please wait.</p>
    </div>
</div>