import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';
import jsPDF from 'jspdf'; // Import jsPDF
import { FileuploderComponent } from '../modules/common/reusable-components/fileuploder/fileuploder.component';
import { environment } from '../../environments/environment';
import { UtilService } from '../core/services/util.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FilePreviewModel } from 'ngx-awesome-uploader';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { StorageService } from '../core/services/storage.service';

interface SectorData {
    id: string;
    name: string;
    __typename: string;
}

@Component({
  selector: 'app-firstmile',
  templateUrl: './firstmile.component.html',
  styleUrl:'./firstmile.component.scss'
})
export class FirstMileComponent implements OnInit, OnDestroy {
handleDocumentUpload($event: any,arg1: string) {
throw new Error('Method not implemented.');
}

handleDocumentRemove($event: any,arg1: string) {
throw new Error('Method not implemented.');
}
  firstMileForm: FormGroup;
  districts: string[] = [];
  sectors: SectorData[] = [
  ];
  loading: boolean = false;
  showModal: boolean = false; // Add a property to control modal visibility
  pdfData: any; // Store the response data for PDF generation
  pdfDoc:any;
  documentPostUrl: any = `${environment.fileUploadUrl}/task/attachment-upload`;
  pan_collection: any[] = [];
  misc_collection:any[]=[];
  compAddress_collection:any[]=[];
  compCOI_collection:any[]=[];
  compPicture_collection:any[]=[];
  aadhar_collection:any[]=[];
  affidavit_collection:any[]=[];
  undertaking_collection:any[]=[];
  fileIDs: any[] = [];
  private docxTemplateUrl = 'assets/files/nda-template-v2.docx';
  private ndaDoc: any; // to store the filled document
  errorMessage: string = ''; // Add this property




  constructor(private fb: FormBuilder, private apollo: Apollo, private router: Router, public _util: UtilService, private _http: HttpClient, private storageService: StorageService) {
    const navigation = this.router.getCurrentNavigation();
    console.log("FMinir", navigation);

    if (navigation && navigation.extras && navigation.extras.state) {
      console.log("Initializing fm enrollment with districts:", navigation.extras.state['sectors']);
      this.districts = navigation.extras.state['districts'] || [];
      this.sectors = navigation.extras.state['sectors'] || [];

      this.firstMileForm = this.fb.group({
        business_name: ['', Validators.required],
        business_type: ['', Validators.required],
        business_nature: ['', Validators.required],
        owner_pan: ['', Validators.required],
        company_pan: ['', Validators.required],
        cin: [''],
        business_address: ['', Validators.required],
        authorized_signatory: ['', Validators.required],
        pincode: ['', [Validators.required, Validators.maxLength(6)]],
        district: ['', Validators.required],
        sector: ['', Validators.required],
        original_enroll_ref: ['', Validators.required],
        owner_email: ['', [Validators.required, Validators.email]],
        owner_mobile: ['', [Validators.required, Validators.maxLength(10)]],
        documentPAN: [false],
        documentAddress: [false],
        documentCOI: [false],
        documentPicture: [false],
        documentAADHAR: [false],
        documentAffidavit: [false],
        documentUndertaking: [false],
        refId: [navigation.extras.state['refId'] || '', Validators.required]
      });
    } else {
      console.warn("No navigation state found. Ensure you are navigating to this route properly.");
      this.firstMileForm = this.fb.group({
        business_name: ['', Validators.required],
        business_type: ['', Validators.required],
        business_nature: ['', Validators.required],
        owner_pan: ['', Validators.required],
        company_pan: ['', Validators.required],
        cin: [''],
        business_address: ['', Validators.required],
        authorized_signatory: ['', Validators.required],
        pincode: ['', [Validators.required, Validators.maxLength(6)]],
        district: ['', Validators.required],
        sector: ['', Validators.required],
        original_enroll_ref: ['', Validators.required],
        owner_email: ['', [Validators.required, Validators.email]],
        owner_mobile: ['', [Validators.required, Validators.maxLength(10)]],
        documentPAN: [false],
        documentAddress: [false],
        documentCOI: [false],
        documentPicture: [false],
        documentAADHAR: [false],
        documentAffidavit: [false],
        documentUndertaking: [false],
        refId: ['', Validators.required]
      });
    }
  }

  ngOnInit() {
    // Get reference number from storage service
    const referenceNumber = this.storageService.getItem('referenceNumber');
    
    if (!referenceNumber) {
      this.router.navigate(['/fm-enrollment']);
      return;
    }

    this.loading = true;
    this.apollo.query<{ fetchEnrollmentByRefID: { email: string; mobile_no: string; pincode: string; district: string | null } }>({
      query: gql`
        query FetchEnrollment($referenceNumber: String!) {
          fetchEnrollmentByRefID(referenceNumber: $referenceNumber) {
            email
            mobile_no
            pincode
            district
          }
        }
      `,
      variables: {
        referenceNumber
      }
    }).subscribe({
      next: (response) => {
        this.loading = false;
        const formData = response.data.fetchEnrollmentByRefID;
        
        // Pre-populate the form with exact field mapping
        this.firstMileForm.patchValue({
          owner_email: formData.email,
          owner_mobile: formData.mobile_no,
          pincode: formData.pincode,
          district: formData.district || ''
        });

        // Disable the pre-populated fields
        this.firstMileForm.get('owner_email')?.disable();
        this.firstMileForm.get('owner_mobile')?.disable();
        this.firstMileForm.get('pincode')?.disable();
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching enrollment data:', error);
        this.errorMessage = 'Failed to load enrollment data. Please try again.';
      }
    });

    // Add event listener for page reload
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
  }

  ngOnDestroy() {
    // Remove event listener when component is destroyed
    window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this));
  }

  private handleBeforeUnload(event: BeforeUnloadEvent) {
    // Show warning message
    const message = 'Are you sure you want to leave? All your form data will be lost.';
    event.preventDefault();
    event.returnValue = message; // Required for Chrome
    
    // If user confirms (clicks "Leave"), navigate to home
    if (!event.defaultPrevented) {
      this.router.navigate(['/fm-enrollment']);
    }
    
    return message; // This will show the browser's default confirmation dialog
  }

  onSubmit() {
    if (this.firstMileForm.valid) {
      this.loading = true;
      this.errorMessage = ''; // Clear any previous errors
      const formValue = this.firstMileForm.getRawValue();

      // Map the input to match the expected GraphQL schema
      const formattedInput = {
          business_name: formValue.business_name,
          business_type: formValue.business_type,
          business_nature: formValue.business_nature,
          owner_pan: formValue.owner_pan,
          company_pan: formValue.company_pan,
          cin: formValue.cin,
          business_address: formValue.business_address,
          authorized_signatory: formValue.authorized_signatory,
          pincode: formValue.pincode,
          district: formValue.district,
          sector: formValue.sector, // Extract the name from the selected sector object
          original_enroll_ref: formValue.original_enroll_ref,
          owner_email: formValue.owner_email,
          owner_mobile: formValue.owner_mobile,
          attachments:this.fileIDs,
          ref_id: formValue.refId // Change to match schema
      };

      this.apollo.mutate({
          mutation: gql`
              mutation createFirstMileEnrollment($input: CreateFirstMileEnrollmentInput!) {
                  createFirstMileEnrollment(input: $input) {
                      id
                      business_name
                      business_type
                      business_nature
                      status
                      sector
                      district
                      business_address
                      authorized_signatory
                      owner_email
                      owner_mobile
                      company_pan
                      owner_pan
                  }
              }
          `,
          variables: {
              input: formattedInput // Use the formatted input
          }
      }).subscribe({
          next: response => {
              console.log('Response from GraphQL:', response);
              this.loading = false;
              this.pdfData = response.data; // Store response data
              this.pdfDoc=this.generateNDApdf(this.pdfData); // Generate PDF
              this.showModal = true; // Show the modal
              console.log("Supposed to show modal"+this.showModal)
          },
          error: (error: any) => {
              console.error('Error occurred:', error);
              this.loading = false;
              
              // Handle GraphQL errors
              if (error.graphQLErrors?.length > 0) {
                  this.errorMessage = error.graphQLErrors[0].error?.[0] || 
                                    error.graphQLErrors[0].message ||
                                    'An error occurred while processing your request';
              } 
              // Handle network errors
              else if (error.networkError) {
                  this.errorMessage = 'Network error occurred. Please check your connection and try again.';
              }
              // Handle other types of errors
              else if (error.errors?.[0]?.error?.[0]) {
                  this.errorMessage = error.errors[0].error[0];
              }
              // Fallback error message
              else {
                  this.errorMessage = 'An unexpected error occurred. Please try again later.';
              }
          }
      });
    }
  }

  generatePDF(data: any) {
    console.log("Data for gen pdf"+data.createFirstMileEnrollment);
      const doc = new jsPDF();
      doc.text(`Business Name: ${data.createFirstMileEnrollment.business_name}`, 10, 10);
      doc.text(`Business Type: ${data.createFirstMileEnrollment.business_type}`, 10, 20);
      doc.text(`Status: ${data.createFirstMileEnrollment.status}`, 10, 30);
      // Add more details as needed
      return doc;
      // Save the PDF
      //
  }

  async generateNDApdf(data: any) {
    try {
      // Fetch the template
      const response = await fetch(this.docxTemplateUrl);
      const templateContent = await response.arrayBuffer();
      
      const zip = new PizZip(templateContent);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        delimiters: {
            start: '{{',
            end: '}}'
        }
      });
      console.log("Business nature", data.createFirstMileEnrollment.business_nature);
      // Set template variables to match new placeholders
      doc.setData({
        business_name: data.createFirstMileEnrollment.business_name || '',
        business_type: data.createFirstMileEnrollment.business_type || '',
        business_nature: data.createFirstMileEnrollment.business_nature || '',
        business_pan: data.createFirstMileEnrollment.company_pan || '',
        business_address: data.createFirstMileEnrollment.business_address || '',
        chosen_sector: data.createFirstMileEnrollment.sector || '',
        chosen_district: data.createFirstMileEnrollment.district || '',
        authorized_signatory: data.createFirstMileEnrollment.authorized_signatory || '',
        owner_email: data.createFirstMileEnrollment.owner_email || '',
        business_location: data.createFirstMileEnrollment.business_address || '',
        date: new Date().toLocaleDateString(),
        owner_mobile: data.createFirstMileEnrollment.owner_mobile || ''
      });

      // Render the document
      doc.render();

      // Store the filled document in class property
      this.ndaDoc = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });

      return this.ndaDoc; // Optional: return the doc if needed

    } catch (error) {
      console.error('Error generating NDA document:', error);
      //todo show error
      //this._util.showError('Error generating NDA document. Please try again.');
    }
  }

  downloadPDF() {
    if (this.pdfDoc) {
        this.pdfDoc.save('FirstMileEnrollment_draft.pdf'); // Trigger the download
    } else {
        console.error('PDF document is not available for download.');
    }
}

downloadNDA() {
    if (this.ndaDoc) {
        const url = window.URL.createObjectURL(this.ndaDoc);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'nda_draft.docx';
        a.click();
        window.URL.revokeObjectURL(url);
    } else {
        console.error('NDA document is not available for download.');
    }
}

closeModal() {
  this.showModal = false; // Hide the modal
  this.router.navigate(['/']); // Redirect to the root route
}

fileValidation(event: any) {
    
}
fileUploaded(event: any) {

}
fileRemoved(event: any) {
}
getSrcFile(event: any, type: any) {
  if(this.fileIDs?.length) {
    this.fileIDs.forEach((img:any,i:number)=>{
      if(img.type === type) {
        this.fileIDs.splice(i,1);
      }
    })
  }
const file = event.file as File;    
this.addFile(event,type)
if (file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e: any) => {
    let file_to_process: any[] = [{ image_url: reader.result, file_name: file?.name }];
    file_to_process = this._util.splitImagesFromFiles(file_to_process);
    this.setParticularArray(type, file_to_process);
  }
}
}
setParticularArray(type: any, obj: any) {
  switch (type) {
    case 'pan':
      this.pan_collection = [...obj]
      break;
    case 'compAddress':
      this.compAddress_collection = [...obj]
      break;
    case 'compCOI':
      this.compCOI_collection=[...obj]
      break;
    case 'compPicture':
      this.compPicture_collection=[...obj]
      break;
    case 'aadhar':
      this.aadhar_collection=[...obj]
      break;
    case 'affidavit':
      this.affidavit_collection = [...obj]
      break;
    case 'undertaking':
      this.undertaking_collection=[...obj]
      break;
  
  }
}
uploadFile(fileItem: FilePreviewModel) {
}
removeFile(event:any,value?:any) {
  this.fileIDs = this.fileIDs.filter(item => 
    !event.some((eventItem: any) => eventItem.file_name === item.file_name)
  );
  switch (value){
    case 'pan':
      this.pan_collection = []
      break;
    case 'compAddress':
      this.compAddress_collection = []
      break;
    case 'compCOI':
      this.compCOI_collection=[]
      break;
    case 'compPicture':
      this.compPicture_collection=[]
      break;
    case 'aadhar':
      this.aadhar_collection=[]
      break;
    case 'affidavit':
      this.affidavit_collection = []
      break;
    case 'undertaking':
      this.undertaking_collection=[]
      break;
        
  } 
}
addFile(fileItem: any,action?:any) {
  const form: FormData = new FormData();
  form.append('files', fileItem.file, fileItem.fileName);
  this._http.post(this.documentPostUrl, form).subscribe({
    next: (res: any) => {
      if(res) res['type'] = action;
      this.fileIDs.push(res);
    },
    error: (err: HttpErrorResponse) => {
    },
    complete: () => { }
  })
}
}