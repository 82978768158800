<div class="first-mile-enroll-container">
  <img src="assets/icons/7h-nexgram-logo-2.svg" alt="Seven Hills Nexgram Logo" class="logo" />

  <h2 class="header-title">First Mile Enrollment</h2>
  <form [formGroup]="enrollForm" (ngSubmit)="onSubmit()" class="enrollment-form">
      <div class="form-group">
          <label for="referenceNumber">Please enter your 7hng associate enrollment form id</label>
          <input id="referenceNumber" formControlName="referenceNumber" type="text" required class="form-control" />
      </div>

      <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</div>
<!-- Loading overlay -->
<div *ngIf="loading" class="loading-overlay">
  <div class="spinner">
    <!-- You can use an SVG spinner or a CSS spinner -->
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" stroke="gray" stroke-width="5" fill="none" />
      <circle cx="25" cy="25" r="20" stroke="blue" stroke-width="5" fill="none" stroke-dasharray="31.4 31.4" stroke-dashoffset="0">
        <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite" />
      </circle>
    </svg>
    <p>Loading... Please wait.</p>
  </div>
</div>