<div class="otp-input-container">
  <img src="assets/icons/7h-nexgram-logo-2.svg" alt="Seven Hills Nexgram Logo" class="logo" />

  <h3 class="otp-title"> First mile enrollment Verify OTP</h3>

  <div class="timer">
    <p>Time remaining: {{ getFormattedTime() }}</p>
  </div>

  <form class="otp-form" [formGroup]="otpForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <input id="emailOtp" formControlName="emailOtp" class="otp-input form-control" maxlength="4" placeholder="Enter Email OTP" required />
    </div>
    <div class="form-group">
      <input id="mobileOtp" formControlName="mobileOtp" class="otp-input form-control" maxlength="4" placeholder="Enter Mobile OTP" required />
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="submit" [disabled]="otpForm.invalid">Submit</button>
    </div>
  </form>

  <!-- Display error message -->
    <div *ngIf="errorMessage" class="error-message text-danger">
      <p>{{ errorMessage }}</p>
    </div>

  <!-- Loading overlay -->
  <div *ngIf="loading" class="loading-overlay">
    <div class="spinner">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" stroke="gray" stroke-width="5" fill="none" />
        <circle cx="25" cy="25" r="20" stroke="blue" stroke-width="5" fill="none" stroke-dasharray="31.4 31.4" stroke-dashoffset="0">
          <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite" />
        </circle>
      </svg>
      <p>Loading... Please wait.</p>
    </div>
  </div>
</div>
