import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';
import { NavigationService } from '../core/services/navigation.service';
import { StorageService } from '../core/services/storage.service';

@Component({
  selector: 'app-firstmile-enroll',
  templateUrl: './firstmile-enroll.component.html',
  styleUrls: ['./firstmile-enroll.component.scss']
})
export class FirstmileEnrollComponent {
  enrollForm: FormGroup;
  loading:boolean=false;
  errorMessage:string='';

  constructor(
    private fb: FormBuilder,
    private apollo: Apollo,
    private router: Router,
    private navigationService: NavigationService,
    private storageService: StorageService
  ) {
    this.enrollForm = this.fb.group({
      referenceNumber: ['', Validators.required] // Ensure reference number is required
    });
  }

  onSubmit() {
    if (this.enrollForm.valid) {
      this.loading = true;
      const referenceNumber = this.enrollForm.value.referenceNumber;

      this.apollo.mutate({
        mutation: gql`
          mutation FirstMileEnrollment($input: InitiateEnrollmentInput!) {
            initiateFirstMileEnrollment(input: $input)
          }
        `,
        variables: {
          input: { referenceNumber }
        }
      }).subscribe({
        next: (res: any) => {
          if (res.data.initiateFirstMileEnrollment) {
            // Store reference number with 500sec expiration
            this.storageService.setItem('referenceNumber', referenceNumber, 500);
            this.loading = false;
            
            // Allow navigation to OTP page
            this.navigationService.setOtpNavigationAllowed(true);
            
            // Navigate to OTP input
            this.router.navigate(['/fm-otp-input'], {
              state: {
                from: 'fm-enrollment',
                timestamp: new Date().getTime()
              }
            });
          } else {
            this.loading = false;
            this.showError('The reference number seems to be invalid, please check');
          }
        },
        error: (err) => {
          this.loading = false;
          this.showError('Something went wrong with the server, please try again');
        }
      });
    }
  }

  private showError(message: string) {
    // Implement your error notification logic here
    this.errorMessage=message;
    console.error(message); // For example, log to console or show a snackbar
  }
}