import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationService } from '../core/services/navigation.service';
import { StorageService } from '../core/services/storage.service';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit, OnDestroy {
  otpForm: FormGroup;
  referenceNumber!: string;
  loading: boolean = false;
  timer: number = 360; // 6 minutes in seconds
  timerSubscription!: Subscription;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder, 
    private apollo: Apollo, 
    private router: Router,
    private navigationService: NavigationService,
    private storageService: StorageService
  ) {
    this.otpForm = this.fb.group({
      emailOtp: ['', Validators.required],
      mobileOtp: ['', Validators.required]
    });
  }

  ngOnInit() {
    // Get reference number from localStorage
    this.referenceNumber = this.storageService.getItem('referenceNumber');
    if (!this.referenceNumber) {
      this.router.navigate(['/fm-enrollment']);
      return;
    }

    
    // Add reload protection
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
    this.startTimer();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this));
  }

  private handleBeforeUnload(event: BeforeUnloadEvent) {
    const message = 'Are you sure you want to leave? Your OTP verification will be cancelled and you will need to request new OTPs.';
    event.preventDefault();
    event.returnValue = message;
    
    if (!event.defaultPrevented) {
      this.storageService.removeItem('referenceNumber');
      this.timerSubscription?.unsubscribe();
      this.navigationService.setOtpNavigationAllowed(false);
      this.router.navigate(['/fm-enrollment']);
    }
    
    return message;
  }

  startTimer() {
    this.timerSubscription = new Subscription();
    const intervalId = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.handleTimeout();
      }
    }, 1000);
    this.timerSubscription.add(() => clearInterval(intervalId));
  }

  handleTimeout() {
    this.timerSubscription.unsubscribe();
    this.storageService.removeItem('referenceNumber');
    this.navigationService.setOtpNavigationAllowed(false);
    this.router.navigate(['/fm-enrollment']);
  }

  getFormattedTime(): string {
    const minutes = Math.floor(this.timer / 60);
    const seconds = this.timer % 60;
    return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  onSubmit() {
    if (this.otpForm.valid) {
      this.loading = true;
      const emailOtp = this.otpForm.value.emailOtp;
      const mobileOtp = this.otpForm.value.mobileOtp;

      this.apollo.mutate({
        mutation: gql`
          mutation FirstMileEnrollment($input: VerifyOTPInput!) {
            verifyOtps(input: $input) {
              message
              data {
                districts
                sectors {
                  id
                  name
                }
              }
              refId
            }
          }
        `,
        variables: {
          input: {
            refId: this.referenceNumber,
            mobileOtp,
            emailOtp
          }
        }
      }).subscribe({
        next: (res: any) => {
          console.log('Response:', res);
          this.loading = false;
          this.router.navigate(['/firstmile'], {
            state: {
              districts: res.data.verifyOtps.data.districts,
              sectors: res.data.verifyOtps.data.sectors,
              refId: res.data.verifyOtps.refId
            }
          });
        },
        error: (err) => {
          this.loading = false;
          console.error('GraphQL Error:', err);
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            // Extract the error message from the GraphQL error response
            const errorResponse = err.graphQLErrors[0];
            this.errorMessage = errorResponse?.error?.[0] || 'An unexpected error occurred. Please try again.'; // Safely access the error message
          } else if (err.networkError) {
            // Handle network errors
            this.errorMessage = 'Network error occurred. Please check your connection.';  
          } else {
            this.errorMessage = 'An unexpected error occurred. Please try again.';
          }
        }
      });
    }
  }
}
