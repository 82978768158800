import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private allowOtpNavigation = false;

  setOtpNavigationAllowed(allowed: boolean) {
    this.allowOtpNavigation = allowed;
  }

  isOtpNavigationAllowed(): boolean {
    const isAllowed = this.allowOtpNavigation;
    this.allowOtpNavigation = false; // Reset after checking
    return isAllowed;
  }
} 